import { useState } from "react";
import { FileObject, Folder } from ".";
import SVGIcon from "../../assets/extensions/svg.svg";
import FolderIcon from "../../assets/extensions/folder.svg";
import UnknownIcon from "../../assets/extensions/unknown.svg";
import { getObject } from "./awsS3";
import React from "react";
import Previewer from "./Previewer";

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0)  return "0 Bytes"
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)) as any)
    return Math.round(bytes / Math.pow(1000, i)) + ' ' + sizes[i]
}

function getExtensionIcon(filename) {
    const extension = filename.split('.').pop();
    switch (extension) {
        case "svg":
            return SVGIcon;
        default:
            return UnknownIcon;
    }
}

type FileBrowserProps = {
    folders: Folder;
};

type RowProps = {
    isSelected?: boolean;
    data: {
        name: string;
        date?: Date;
        size?: number;
    };
    onClick?: (e) => void;
    isFolder?: boolean;
};

function Row(props: RowProps) {
        return (
            <tr className={`fileRow ${props.isSelected ? 'selected' : ''}`} onClick={props.onClick}>
                <td>
                    {
                        props.data.name === ".." ? 
                        null : 
                         (
                            props.isFolder ? 
                            <img src={FolderIcon} alt="icon" height={24} width={24} /> :
                            <img src={getExtensionIcon(props.data.name)} alt="icon" height={24} width={24} />
                         )
                    }
                </td>
                <td className="RowFilename">{props.data.name}</td>
            </tr>
    )
}

function FileBrowser(props: FileBrowserProps) {
    const [currentPath, setCurrentPath] = useState<string[]>([]);
    const [previewFile, setPreviewFile] = useState<FileObject | null>(null);
    const [lastClicked, setLastClicked] = useState<string | null>(null);

    const filename = previewFile?.Key.split("/").pop() as string;

    let folder = props.folders;
    if (currentPath.length > 0) {
        currentPath.forEach((folderName) => {
            folder = folder.folders[folderName];
        });
    }

    const handleFolderClick = (e, folderName: string) => {
        setLastClicked(folderName)
        if (e.detail === 2) {
            setCurrentPath([...currentPath, folderName]);
        }
    }

    const handleParentClick = (e) => {
        if (e.detail === 2) {
            setCurrentPath(currentPath.slice(0, currentPath.length - 1));
        }
    }

    const handleFileClick = async (e, file) => {
        setLastClicked(file.Key)
        setPreviewFile(file);
    }

    async function download() {
        const object = await getObject(previewFile?.Key as string);
        const blob = new Blob([object.Body]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = filename;
        link.href = url;
        link.click();
        link.remove();
    }

    return (
        <div className="fileBrowser">
            <div>
                <p className="bold">{ "/" + currentPath.join("/") }</p>
                <table>
                    <tbody>
                        {
                            currentPath.length > 0 
                            ? <Row onClick={handleParentClick} data={{name: ".."}}></Row>
                            : null
                        }
                        {
                            Object.keys(folder.folders).map((folderName) => {
                                return <Row isSelected={folderName === lastClicked} onClick={(e) => handleFolderClick(e, folderName)} data={{name: folderName}} isFolder={true}/>
                            })
                        }
                        {
                            Object.keys(folder.files).map((fileName) => {
                                const file = folder.files[fileName];
                                return <Row isSelected={file.Key === lastClicked} onClick={(e) => handleFileClick(e, file)} data={{name: fileName, date: folder.files[fileName].LastModified, size: folder.files[fileName].Size}}/>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div>
                <p className="bold">Preview</p>
                {/* TODO: preview differently based on file type */}
                
                {previewFile ? (
                    <React.Fragment>
                        <div>
                            <Previewer file={previewFile} />
                        </div>
                        <p>Name: { previewFile ? previewFile?.Key.split("/").pop() : "" }</p>
                        <p>Size: { previewFile ? bytesToSize(previewFile?.Size) : "" }</p>
                        <p>Modified: { previewFile ? previewFile?.LastModified.toLocaleString() : "" }</p>
                        {/* TODO: use an actual button */}
                        <p onClick={download} className="bold">Download</p>
                    </React.Fragment>
                ) : null}
            </div>
        </div>
    )
}

export default FileBrowser;