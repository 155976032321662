/**
 * Return a value depending on if it is defined or not 
 * @param expectedValue value to check if defined
 * @param defaultValue value to return if expectedValue is undefined
 * @returns {T}
 */
function ifDefined<T>(expectedValue: T, defaultValue: T) {
   if (expectedValue !== undefined) {
      return expectedValue;
   }
   return defaultValue;
}

export { ifDefined };