import React, { useContext, useEffect } from 'react';
import Icon from './components/Icon';
import { WindowManagerContext } from './store/WindowManager';
import FolderIcon from "./assets/extensions/folder.svg";

function Background(props) {
    const windowManager = useContext(WindowManagerContext)

    useEffect(() => {
        windowManager?.createWindows([
            "files",
            "cv",
            "projects",
            "welcome",
        ])
    }, []);

    return (
        <React.Fragment>
            <div style={{
                position: "absolute",
                left: "0",
                right: "0",
            }}>
                <p>Theta OS</p>
                <p>v1.0.0-beta</p>
                <p>[logo_here]</p>
            </div>
            <p style={{
                position: "absolute",
                bottom: "40px",
                right: "10px",
            }}>[Created using THETA design phillosophies]</p>
            <Icon type="files" x={100} y={100} appIcon={ FolderIcon }/>
        </React.Fragment>
    )
}

export default Background;