import { App } from "../AppManager";

function CV() {
  return (
    <div style={{
      textAlign: "left",
      width: 'fit-content',
      margin: 'auto',
    }}>
      <h2>Technologies</h2>
      <ul>
        <li>Javascript + Typescript</li>
        <ul>
          <li>NodeJS (Express, Restify and NestJS)</li>
          <li>ReactJS</li>
          <li>Testing with Jest</li>
        </ul>
        <li>GraphQL</li>
        <li>HTML + CSS</li>
        <li>MongoDB + Mongoose</li>
        <li>Docker</li>
        <li>Git</li>
        <li>AWS</li>
        <ul>
          <li>S3</li>
          <li>Lambda</li>
          <li>SNS</li>
          <li>Event Bridge</li>
          <li>Cognito</li>
          <li>Cloud Formation</li>
        </ul>
        <li>Rust</li>
      </ul>
      <h2>Experience</h2>
      <ul>
        <li>Microservices Developer - <a href="https://www.thrivelearning.com/">THRIVE</a> (Nov 2022 - Present)</li>
        <ul>
          <li>Created POCs for features needed</li>
          <li>Developed integrations syncing platform users with HRIS systems</li>
        </ul>
        <li>Junior Microservices Developer - <a href="https://www.thrivelearning.com/">THRIVE</a> (Jul 2021 - Aug 2022)</li>
        <ul>
          {/* TODO: add more */}
          <li>Worked in an agile team developing microservices</li>
          <li>Created and maintained microservices</li>
          <li>Fixed high priority bugs and issues on the platform</li>
          <li>Created migration scripts for production databases</li>
          <li>Mentored new starters in the team</li>
        </ul>
      </ul>
      <h2>Education</h2>
      <ul>
        <li>BSc Computer Science - The University of Sheffield (2022 - Present)</li>
        <li>A Levels (John Taylor High School)</li>
        <ul>
          <li>Computer Science - A*</li>
          <li>Physics - A</li>
          <li>Maths - A</li>
        </ul>
      </ul>
    </div>
  );
}

const cv: App = {
    name: "CV",
    component: CV,
    defaultHeight: 800,
    defaultWidth: 800,
    minHeight: 400,
    minWidth: 400,
};

export default cv;