import CV from "./CV";
import Files from "./Files";
import Projects from "./Projects";
import Welcome from "./Welcome";

type App = {
    name: string;
    component: React.FC;
    defaultHeight?: number;
    defaultWidth?: number;
    minHeight?: number;
    minWidth?: number;
    startX?: number;
    startY?: number;
};


const apps: {[key: string]: App} = {
    files: Files,
    welcome: Welcome,
    cv: CV,
    projects: Projects
};

type AppName = keyof typeof apps

function get(name: string) {
    return apps[name];
}

const AppManager = {    
    get,
    apps
};

export default AppManager;

export type {
    App,
    AppName,
};