import React, { useContext } from 'react';
import { useMeasure } from 'react-use';
import { ifDefined } from '../helpers/ifDefined';
import { WindowManagerContext } from '../store/WindowManager';
import Window from './Window';

function WindowManager(props) {
    const [ref, { height, width}] = useMeasure<HTMLDivElement>();
    const windowManager = useContext(WindowManagerContext)

    const handleClick = (id) => {
        windowManager?.pushToTop(id);
    }

    return (
        <React.Fragment>
            <div className="WindowBounds" ref={ref}>
                {
                    props.children
                }
                {windowManager?.windows.map((window, id) => {
                    const defaultWidth = window.defaultWidth || 400; 
                    const defaultHeight = window.defaultHeight || 300; 
                    const startX = window.startX && window.startX < 0 
                     ? width + window.startX - defaultWidth 
                     : window.startX;
                    const startY = window.startY && window.startY < 0 
                     ? height + window.startY - defaultHeight 
                     : window.startX;
                    return <Window 
                        key={window.pid}
                        minimise={window.minimised}
                        title={window.title}
                        index={window.index}
                        children={window.children}
                        height={defaultHeight}
                        width={defaultWidth}
                        minHeight={window.defaultHeight}
                        minWidth={window.defaultWidth}
                        startX={ifDefined(startX, Math.floor(Math.random() * 100))}
                        startY={ifDefined(startY, Math.floor(Math.random() * 100))}
                        onMinimise={() => windowManager?.minimiseWindow(id)}
                        onClose={() => windowManager?.removeWindow(id)}
                        onClick={() => handleClick(id)}
                    />
                })}
            </div>
            <div className="WindowBar">
                {
                    windowManager?.windows.map((window, id) => {
                        return <button className={window.minimised?"minimised":""} key={id} onClick={() => windowManager?.toggleVisibility(id)}>{window.title}</button>
                    })
                }
            </div>
        </React.Fragment>
    );
}

export default WindowManager;