import { useContext } from "react";
import { WindowManagerContext } from "../../store/WindowManager";
import DefaultIcon from "../../assets/extensions/unknown.svg";
import "./Icon.css";
import AppManager from "../../apps/AppManager";

type IconProps = {
    type: keyof typeof AppManager.apps;
    x?: number;
    y?: number;
    appIcon?: any;
};

function Icon(props: IconProps) {
    const windowManager = useContext(WindowManagerContext)

    const app = AppManager.get(props.type as string);

    const handleOpen = () => {
        windowManager?.createWindow(props.type);
    }

    let icon = props.appIcon || DefaultIcon;

    const handleClick = (e) => {
        console.log("click")
        // Handle double click
        if (e.detail === 2) {
            console.log("double click")
            handleOpen();
        }
    }

    return (
        <div
            className="Icon"
            style={{
                top: props.y,
                left: props.x,
            }}
            onClick={handleClick}
        >
            <img height={40} src={icon} alt="Icon" />
            <p>{app.name}</p>
        </div>
    );
}

export default Icon;