// Render a file preview 

import { FileObject } from ".";

function Previewer(props: { file: FileObject | null }) {
    const extension  = props.file?.Key.split(".").pop() as string;
    let Render = Image;

    // TODO: Add switch for file types
    switch (extension) {
        case 'mp4':
            Render = Video;
            break;
        default:
            Render = Image;
            break;
    }

    console.log({Render})

    return (
        <div>
            <Render file={props.file} />
        </div>
    )
}

function Image(props) {
    return (
        <img 
                src={
                    props.file?.Key ?
                        `https://b2-proxy.benmagill.workers.dev/${props.file.Key}`:
                        ""
                    } 
                    alt="" 
                    width={400}
                    style={{
                        margin: "auto",
                        width: "400px",
                        height: "400px",
                        objectFit: "contain"
                    }}
                />
    )
}

function Video(props) {
    return (
        <video 
            controls
            width={400}
            autoPlay
        >
            <source 
                src={`https://b2-proxy.benmagill.workers.dev/${props.file.Key}`} 
                type="video/mp4" />
        </video>
    )
}

export default Previewer;