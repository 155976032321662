import React, {useState} from 'react'
import AppManager, { AppName } from '../apps/AppManager';

type WindowType = {
    pid: number;
    title: string;
    index: number;
    minimised: boolean;
    children?: any;
    defaultHeight?: number;
    defaultWidth?: number;
    minHeight?: number;
    minWidth?: number;
    startX?: number;
    startY?: number;
}

type WindowManagerContextType = {
    windows: WindowType[]
    createWindow: (appName: AppName) => void;
    createWindows: (appNames: AppName[]) => void;
    updateWindow: (id: number, changes: any) => void;
    toggleVisibility: (id: number) => void;
    minimiseWindow: (id: number) => void;
    removeWindow: (id: number) => void;
    pushToTop: (id: number) => void;
};

export const WindowManagerContext = React.createContext<WindowManagerContextType | null>(null)

let currentPID = 0;
const getPID = () => {
    return currentPID++;
}

export function WindowManagerProvider(props){


    const [windows, setWindows] = useState<WindowType[]>([]);

    const updateWindow = (id, changes) => {
        const clonedWindows = [...windows];
        clonedWindows[id] = Object.assign({}, windows[id], changes);
        setWindows(clonedWindows);
    }
    
    let [maxIndex, setMaxIndex] = useState(windows.length);
    
    const createWindow = (appName: AppName) => {
        createWindows([appName]);
    }

    const createWindows = (appNames: AppName[]) => {
        console.log("Creating windows", appNames)

        // duplicate values
        const clonedWindows = [...windows];
        let newMaxIndex = maxIndex;
        
        appNames.forEach((name) => {
            const app = AppManager.get(name as string);
            console.log("Creating window", app)
            newMaxIndex++;
            clonedWindows.push({
                pid: getPID(), // unique id of each window
                title: app.name,
                defaultHeight: app.defaultHeight,
                defaultWidth: app.defaultWidth,
                minHeight: app.minHeight,
                minWidth: app.minWidth,
                startX: app.startX,
                startY: app.startY,
                children: <app.component />,
                index: newMaxIndex,
                minimised: false,
            })
        })

        setWindows(clonedWindows);
        setMaxIndex(newMaxIndex);
    };

    const toggleVisibility = (id) => {
        const window = windows[id];
        updateWindow(id, { minimised: !window.minimised })
        if (window.minimised) {
            pushToTop(id);
        }
    }

    const minimiseWindow = (id) => {
        updateWindow(id, { minimised: true })
    }

    const removeWindow = (id) => {
        const clonedWindows = [...windows]
        clonedWindows.splice(id, 1);
        setWindows(clonedWindows)
    }

    const pushToTop = (id) => {
        const window = windows[id];
        if (window?.index !== maxIndex) {
            setMaxIndex(maxIndex+1);
            updateWindow(id, {
                index: maxIndex+1,
                minimised: false,
            })
        }
    }

    return (
        <WindowManagerContext.Provider
            value= {{
                windows,
                pushToTop,
                createWindow,
                createWindows,  
                updateWindow,
                toggleVisibility,
                minimiseWindow,
                removeWindow,
            }}
        >
            {props.children}
        </WindowManagerContext.Provider>
    )
    
}