import AWS from 'aws-sdk';

AWS.config.credentials = null;
const BUCKET = "b2-proxy";

const s3 = new AWS.S3({
    endpoint: 'benmagill.workers.dev',
});

export async function listObjects() {
    const objects = await s3.makeUnauthenticatedRequest("listObjects", {
        Bucket: BUCKET
    }).promise();
    return objects.Contents;
}

export async function getObject(key: string) {
    const object = await s3.makeUnauthenticatedRequest("getObject", {
        Bucket: BUCKET,
        Key: key
    }).promise();
    console.log(object)
    return object;
}