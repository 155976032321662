import { useEffect, useState } from 'react';
import { listObjects } from './awsS3';
import FileBrowser from './FileBrowser';
import "./index.css";

type FileObject = {
  Key: string;
  LastModified: Date;
  Size: number;
  ETag: string;
};

type Folder = {
  folders: { [folderName: string]: Folder};
  files: { [fileName: string]: FileObject};
}

function Files() {
  // store folder struct in a state
  const [folders, setFolders] = useState<Folder>({
    folders: {},
    files: {},
  });
  
  const mapObjectsToStructure = (objects: FileObject[]) => {
    const structure: Folder = {
      folders: {},
      files: {},
    };
    objects.forEach((object) => {
      const path = object.Key.split('/');
      let currentFolder = structure;
      path.forEach((sectionName, index) => {
        if (index === path.length - 1) {
          if (sectionName === '.bzEmpty') return;

          // Create file
          currentFolder.files[sectionName] = object;
        } else {
          if (!currentFolder.folders[sectionName]) {
            // create next level if doesn't exist
            currentFolder.folders[sectionName] = {
              folders: {},
              files: {},
            };
          }
          // set current folder to next level
          currentFolder = currentFolder.folders[sectionName];
        }
      })
    })
    return structure;
  }

  useEffect(() => {
    const func = async () => {
      const objects = await listObjects();
      const folders = mapObjectsToStructure(objects)
      setFolders(folders)
    }
    func()
  }, []);


  return (
    <FileBrowser folders={folders} />
  );
}

const files = {
  name: "Files",
  defaultHeight:600,
  defaultWidth:1000,
  minHeight: 600,
  minWidth: 1000,
  component: Files
};

export default files;
export type { FileObject, Folder };