import { App } from "../AppManager";

function Welcome() {
  return (
    <div>
      <h1>Welcome to Theta_OS</h1>
      <p>An interactive portfolio to show case my work</p>
      <br/>
      <p>Contact: inbox@benmagill.co.uk</p>
      <p>Website code: [github]</p>
    </div>
  );
}

const welcome: App = {
    name: "README.txt",
    component: Welcome,
    defaultHeight: 400,
    defaultWidth: 400,
    minHeight: 400,
    minWidth: 400,
    startX: -1,
    startY: -20,
};

export default welcome;