import WindowManager from "./components/WindowViewer";
import './App.css';
import Background from "./Background";

function App() {
  return (
    <div className="App">
      <WindowManager> 
        <Background />
      </WindowManager>
    </div>
  );
}

export default App;