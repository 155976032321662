import React from 'react';
import { Rnd } from "react-rnd";
import './Window.css';

type WindowProps = {
    minimise: boolean;
    index?: number;
    title?: string;
    minHeight?: number;
    minWidth?: number;
    width: number;
    height: number;
    startX?: number;
    startY?: number;
    onMinimise: () => void;
    onClose: () => void;
    onClick: () => void;
    children?: any;
}

function Window(props: WindowProps) {
    const zIndex = props.index || 1;
    const minimised = props.minimise;
    
    const title = props.title || "Default title";
    
    const minHeight = props.minHeight || 200;
    const minWidth = props.minWidth || 320;
    const width = props.width || 400;
    const height = props.height || 300;
    const startX = props.startX || 0;
    const startY = props.startY || 0;
    
    const minimise = props.onMinimise;
    const close = props.onClose;
    const onClick = props.onClick;

    return (
        <div style={{display: minimised ? "none": "block"}}>

            <Rnd
                className="window"
                bounds=".WindowBounds"
                dragHandleClassName="windowHeader"
                style={{
                    zIndex,
                    display: minimised ? "none" : "block",
                }}
                default={{
                    x: startX,
                    y: startY,
                    width,
                    height,
                }}
                minHeight={minHeight}
                minWidth={minWidth}

                onMouseDown={onClick}
            >
                <div className="windowHeader">
                    <p className="windowTitle">{title}</p>
                </div>
                <div className="windowControls">
                    <button className="windowControlButton" onClick={minimise}>-</button>
                    <button className="windowControlButton" onClick={close}>X</button>
                </div>
                <div className="windowBody">
                    {props.children}
                </div>
            </Rnd>
        </div>
    )
}

export default Window;
