import { MemoryRouter, Route, Routes, useNavigate, useParams } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import { App } from "../AppManager";
import Card from "react-bootstrap/Card";
import './index.css';
import { Container, Row } from "react-bootstrap";

const projectsData = [
  {
    name: "Redoot",
    description: "An instant messaging website designed with privacy first. You can find and send requests to friends and create group chats with them.",
    shortDescription: "An instant messaging website designed with privacy first.",
    images: [
      "https://via.placeholder.com/150x100",
      "https://via.placeholder.com/150x100",
    ],
  },
  {
    name: "URLx",
    shortDescription: "A simple URL shortener, with tracking.",
    description: "A project",
    images: ["https://via.placeholder.com/150x100"],
  },
  {
    name: "Minecraft world viewer",
    shortDescription: "A desktop application to view Minecraft world and player data.",
    description: "A desktop application to view Minecraft world and player data. Built with Electron and React.",
    images: ["https://via.placeholder.com/150x100"],
  },
  {
    name: "Calculator",
    shortDescription: "A simple calculator built with React.",
    description: "A simple calculator built with React. It uses a binary tree to parse the input and evaluate it.",
    images: ["https://via.placeholder.com/150x100"],
  },
  {
    name: "LMC Simulator",
    shortDescription: "Web based simulator for LMC (Little Man Computer) code.",
    description: "Web based simulator for LMC (Little Man Computer) code. Includes visualisation of the memory and registers.",
    images: ["https://via.placeholder.com/150x100"],
  },
  {
    name: "Personal Cloud Storage",
    shortDescription: "Self hostable cloud storage.",
    description: "",
    images: ["https://via.placeholder.com/150x100"],
  },
  {
    name: "HTTP DB",
    shortDescription: "Database similar to MongoDB which is controlled via HTTP requests.",
    description: "",
    images: ["https://via.placeholder.com/150x100"],
  },
  {
    name: "SteamBrew (WIP)",
    shortDescription: "Tools to allow running extensions on the Steam Deck.",
    description: "Tools to allow running extensions on the Steam Deck. Includes installing from the internet and customising the UI.",
    images: ["https://via.placeholder.com/150x100"],
  },
  {
    name: "Shell Runner",
    shortDescription: "Tool for executing shell commands in a docker container.",
    description: "Tool for executing shell commands in a docker container. The output of commands is matched to the input.",
    images: ["https://via.placeholder.com/150x100"],
  },
  {
    name: "Blog Gen",
    shortDescription: "Tool for generating a static blog from markdown files.",
    description: "Tool for generating a static blog from markdown files. Automatically generates a contents page.",
    images: ["https://via.placeholder.com/150x100"],
  },
].reverse();

function ProjectCard({ navigate, index, project }) {
  return (
    <Card onClick={() => navigate("/" + index.toString())} style={{
        width: '18rem',
        margin: "1rem",
      }}>
      <Card.Img variant="top" src={project.images[0]} />
      <Card.Body>
        <Card.Title>{ project.name }</Card.Title>
        <Card.Text>
          { project.shortDescription }
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

function All() {
  const navigate = useNavigate();

  return (
    <div>
      <Container style={{
        width: "fit-content",
      }}>
        {
          projectsData.map((_, index) => {
            if (index % 2 === 1) return null;
            return (
              <Row>
                <ProjectCard key={index} navigate={navigate} index={index} project={projectsData[index]} />
                {
                  projectsData[index+1] 
                  ? <ProjectCard key={index+1e2} navigate={navigate} index={index+1} project={projectsData[index+1]} />
                  : null
                }
              </Row >
            )
          })
        }
      </Container>
    </div>
  );
}

function Project() {
  const { project: projectIndex } = useParams();
  const navigate = useNavigate();
  const project = projectsData[projectIndex || 0];

  return (
    <div>
      <button onClick={() => navigate("/")} style={{
        float: "left",
      }}>Back</button>
      <h1>{ project.name }</h1>
      <div style={{
        width: "70%",
        margin: "auto",
      }}>
        <Carousel>
          {
            project.images.map((image, index) => {
              return (
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={project.images[0]}
                    alt="First slide"
                  />
                </Carousel.Item>
              )
            })
          }
        </Carousel>
      </div>
      <p>{ project.description }</p>
    </div>
  );
}

function Projects() {
  return (
    <MemoryRouter>
      <Routes>
        <Route path="/" element={<All />} />
        <Route path="/:project" element={<Project />} />
      </Routes>
    </MemoryRouter>
  );
}

const projects: App = {
    name: "Projects",
    component: Projects,
    defaultHeight: 600,
    defaultWidth: 800,
    minHeight: 400,
    minWidth: 400,
};

export default projects;